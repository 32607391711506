import React from "react";
import { Form } from "../components/display/form";
import { Master } from "../components/layout/master";
import styled from "styled-components";
import { Footer } from "../components/layout/footer";
import { Header } from "../components/layout/header";
import Layout from "../components/layout/layout";
import { Section } from "../components/sections/section";
import { themes } from "../components/styles/ColorsStyle";
import { MediumText, TextSmall, Wrapper } from "../components/styles/TextStyles";

export function Pagepolicy(props) {
  return (
    <Layout>
      <Header {...props}/>
        <br/>
      <Section title="Política de Privacidad">
          
      <Title>1. Introducción</Title>
Tcontakto S.A.C (en adelante “Tcontakto”) es una empresa privada que brinda servicios de operación logística en el Perú. En ese sentido, nos comprometemos a mantener la privacidad y la protección de información de nuestros prospectos de clientes, clientes, proveedores y colaboradores de conformidad con lo establecido en la Ley No. 29733, Ley de Protección de datos personales, su Reglamento y normas complementarias, adoptando para ello las medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados por los titulares de datos personales. Asimismo, Tcontakto garantiza la mejora continua de estas medidas. En tal contexto, declaramos los siguientes lineamientos que debemos informar a nuestros prospectos de clientes, clientes, proveedores y colaboradores, de forma clara e inequívoca, cuando se recaben sus datos personales a través de cualquiera de nuestros canales:

La existencia del tratamiento de datos de carácter personal, la finalidad de la recolección y destinatarios de la información.

Carácter obligatorio o facultativo de la respuesta a las preguntas que en su caso les sean planteadas, así como de las consecuencias de la obtención de los datos personales o la negativa a suministrar los mismos.

Posibilidad de ejercer sus derechos de acceso, rectificación, cancelación y oposición.

Identidad y dirección del responsable del tratamiento de los datos personales.

El prospecto de cliente, cliente, proveedor o colaborador será el único responsable de los datos personales que consigne en los formularios. Siendo ellos los únicos responsables en caso se consignen datos personales falsos, inexactos, incompletos o no actualizados. Cualquier tipo de cesión a terceros de los datos personales de nuestros clientes, proveedores y/o colaboradores de este banco de datos, será comunicada a través de la presente Política de Privacidad, especificando la identidad de los cesionarios y la finalidad con la que se van a tratar los datos personales que están siendo cedidos.


<Title>2. Definiciones</Title>

Datos Personales: Toda información sobre una persona natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados. Por ejemplo, nombres, documentos de identidad, números telefónicos, entre otros, que identifican o pueden identificar a un individuo.

Tratamiento de datos personales: Cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales.


<Title>3. Principios rectores</Title>

Tcontakto o, en su caso, quien resulte responsable del tratamiento, cumple con los principios rectores de la protección de datos personales:

Principio de consentimiento:

En atención al principio de consentimiento, el tratamiento de los datos personales es lícito cuando el titular del dato personal hubiere prestado su consentimiento libre, previo, expreso, informado e inequívoco. No se admiten fórmulas de consentimiento en las que éste no sea expresado de forma directa, como aquellas en las que se requiere presumir, o asumir la existencia de una voluntad que no ha sido expresa. Incluso el consentimiento prestado con otras declaraciones, deberá manifestarse en forma expresa y clara.

Principio de finalidad:

En atención al principio de finalidad se considera que una finalidad está determinada cuando haya sido expresada con claridad, sin lugar a confusión y cuando de manera objetiva se especifica el objeto que tendrá el tratamiento de los datos personales.

Tratándose de banco de datos personales que contengan datos sensibles, su creación solo puede justificarse si su finalidad además de ser legítima, es concreta y acorde con las actividades o fines explícitos del titular del banco de datos personales.

Los profesionales que realicen el tratamiento de algún dato personal, además de estar limitados por la finalidad de sus servicios, se encuentran obligados a guardar secreto profesional.

Principio de calidad:

En atención al principio de calidad, los datos contenidos en un banco de datos personales, deben ajustarse con precisión a la realidad. Se presume que los datos directamente facilitados por el titular de los mismos son exactos.

Principio de seguridad:

En atención al principio de seguridad, en el tratamiento de los datos personales deben adoptarse las medidas de seguridad que resulten necesarias a fin de evitar cualquier tratamiento contrario a la Ley o a su reglamento, incluyéndose en ellos a la adulteración, la pérdida, las desviaciones de información, intencionales o no, ya sea que los riesgos provengan de la acción humana o del medio técnico utilizado.


<Title>4. Cláusula de Protección de Datos Personales</Title>

Tcontakto, recopila su información personal, tales como nombres completos, números telefónicos, correos electrónicos, documentos de identidad; entre otros, que resulten necesarios para cumplir con los fines que se detallan más adelante, por medio de formularios físicos, formularios virtuales contenidos en su página web, a través de nuestros Call Centers y /o de cualquiera de nuestros canales, hasta que se cumpla las finalidades de tratamiento o decida revocar su consentimiento. Su información será almacenada por el tiempo necesario para cumplir las finalidades descritas en el acápite siguiente, según corresponda, en nuestros Bancos de Datos Personales de Prospectos de Clientes, Clientes, Proveedores y/o Colaboradores, los cuales se encuentran debidamente inscritos, conforme a la exigencia legal en materia de protección de datos personales.


<Title>5. Finalidades</Title>

Los datos personales de los cuales se realiza el tratamiento, tienen las siguientes finalidades:

Prospecto de Clientes:

Registrar y almacenar los datos personales de posibles clientes, personas interesadas, y ex clientes para el envío de ofertas, promociones y/o publicidad.

Clientes:

Contar con un registro de información de todos los clientes. Esta base de datos es utilizada para el envío de beneficios comerciales, ofertas, promociones y/o publicidad, así como para definir estrategias comerciales, definir tendencias, comportamientos de consumos y elaborar los planes tarifarios, definir ofertas y promociones atractivas a los clientes.

Proveedores:

Mantener un control de la información de nuestros proveedores, creamos files de información de cada uno. Estos files se actualizan periódicamente y forman parte de nuestra evaluación al momento de decidir la compra de bienes o adquisición de servicios.

Colaboradores:

Tener una base de datos actualizada del personal de la empresa.


<Title>6. Seguridad y confidencialidad</Title>

Tcontakto cuenta con todas las medidas técnicas, legales y organizacionales necesarias para garantizar la seguridad y confidencialidad de sus datos personales. Sus datos personales serán tratados considerando los principios de legalidad, consentimiento, proporcionalidad, calidad, seguridad y los demás presentes en la normativa vigente referente a Protección de Datos Personales, evitando que estos sean filtrados, difundidos o cualquier acción que ponga en peligro su información personal.


<Title>7. Transferencia de datos personales a terceros</Title>
Al brindar su consentimiento, usted autoriza a Tcontakto para compartir con sus socios comerciales los datos personales recolectados y estos poder usar los datos personales para las finalidades antes descritas, de conformidad con esta Política de Privacidad. Asimismo, usted autoriza a Tcontakto y a sus socios comerciales a compartir los datos personales recolectados a través de empresas nacionales o extranjeras que operen para Tcontakto y/o sus socios comerciales. Es posible que, para ejecutar sus funciones dichas empresas necesiten información sobre usted; cabe indicar que, éstas no se encuentran autorizadas para usar los datos personales que compartimos con ellas para ningún otro fin más que el indicado en los términos de esta Política de Privacidad. Cuando Tcontakto proceda de esta manera, adoptará las precauciones que según la legalidad vigente sean necesarias para proteger la confidencialidad y seguridad de sus datos personales.
Puede haber, además, otras circunstancias en las cuales Tcontakto o sus socios comerciales pueden compartir o transferir a un tercero ajeno los datos personales almacenados en sus bases de datos, para, por ejemplo, cumplir una exigencia legal, colaborar con la administración de justicia, proteger intereses que son cruciales para usted o su empresa, y en los casos de venta, fusión, reorganización y disolución de la compañía u otros similares. Cuando sea adecuado, antes de revelar datos personales a un tercero, Tcontakto le exigirá contractualmente a éste que adopte las precauciones necesarias para proteger los datos contra su uso o revelación no autorizados.


<Title>8. Ejercicio de sus derechos</Title>

Tcontakto, preocupado porque se brinde el mejor tratamiento de sus datos personales
, le informa que usted tiene el derecho de ejercer sus derechos de acceso, rectificación, cancelación 
y oposición. Para ello debes enviarnos una solicitud al correo electrónico protecciondatospersonales@tcontakto.com. Los requisitos de cada solicitud y el plazo de atención se encuentran detallados en la ley N° 29733 y en el Decreto Supremo N° 003-2013-JUS.


<Title>9. Cambios en la Política de Privacidad</Title>

Tcontakto se reserva el derecho de modificar y/o actualizar la presente Política de Privacidad como parte de su mejora continua, ya sea para adaptarla a futuros cambios normativos, brindar una mejor calidad de servicio o comunicar nuevas alternativas relacionadas a la presente Política.
Usted puede acceder a esta Política de Privacidad en cualquier momento haciendo clic en el botón marcado como “Política de Privacidad”, situado en el extremo superior derecho de la página web de Tcontakto.

      </Section>

    </Layout>
  );
}


const Title = styled(MediumText)`
  color: ${themes.light.gray};
  margin-bottom: 20px;
  font-weight: bold;
`;