import React from "react";
import { Form } from "../components/display/form";
import { Master } from "../components/layout/master";
import { Clients } from "../components/sections/clients";

export function Pageclients(props) {
  return (
    <Master {...props}>
      <Clients {...props}/>
    </Master>
  );
}