import React from "react";
import styled from "styled-components";

export function Header() {
  return (
    <HeaderStyled>
      <h1>header</h1>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.header`
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 20px;
`;
