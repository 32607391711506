import React from "react";
import { Form } from "../components/display/form";
import { Master } from "../components/layout/master";
import styled from "styled-components";
import { Footer } from "../components/layout/footer";
import { Header } from "../components/layout/header";
import Layout from "../components/layout/layout";
import { Section } from "../components/sections/section";
import { themes } from "../components/styles/ColorsStyle";
import { MediumText, TextSmall, Wrapper } from "../components/styles/TextStyles";

export function Pagesgsi(props) {
  return (
    <Layout>
      <Header {...props}/>
        <br/>
      <Section title="Alcance del SGSI">
          
      {/* <Title>Alcance del SGSI</Title> */}

      <Parrafo>
      Con la finalidad de proteger la integridad, confidencialidad y disponibilidad de la información de nuestros clientes, hemos implementado un Sistema de Gestión de Seguridad de Información con el alcance al flujo de inicio y fin del proceso de Venta de productos financieros mediante telemarketing.
      Para tal efecto, hemos reforzado nuestras medidas de control y prácticas de gestión en materia de seguridad de la información, ciberseguridad y protección de datos personales a efectos de: 
      </Parrafo>

      <Ul>
        <li>Evitar la adulteración, pérdida, tratamiento o acceso no autorizado que puedan afectar a la información de nuestros clientes.</li>
        <li>Asegurar la continuidad y disponibilidad de los procesos y servicios digitales que brindamos.</li>
      </Ul>      
      
      </Section>

    </Layout>
  );
}

const Ul = styled.ul`
color: ${themes.light.gray};
`;

const Parrafo = styled.p`
color: ${themes.light.gray};
margin-bottom: 20px;
text-align: justify;
`;


const Title = styled(MediumText)`
  color: ${themes.light.gray};
  margin-bottom: 20px;
  font-weight: bold;
`;

