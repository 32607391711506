import React from "react";
import styled, { keyframes } from "styled-components";
import { themes } from "../styles/ColorsStyle";

export const Loader = (props) => {
  if (props.message) {
    return (
      <Content>
        <Wrapped>
          <LoaderCircle />
          <AnimatedLine>
            <LoaderLine />
          </AnimatedLine>
          <Text>{props.message}</Text>
        </Wrapped>
      </Content>
    );
  } else {
    return (
      <Content>
        <Message>
          <Wrap>O</Wrap>
          <Wrap>D</Wrap>
          <Wrap>N</Wrap>
          <Wrap>A</Wrap>
          <Wrap>G</Wrap>
          <Wrap>R</Wrap>
          <Wrap>A</Wrap>
          <Wrap>C</Wrap>
        </Message>
      </Content>
    );
  }
};

const Wrapped = styled.div`
`
const Content = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${themes.light.primary};
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
const Inner = styled.div``;

const LoaderCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
`;
const rotate = keyframes`
   0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
const LoaderLineMask = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
`;
const AnimatedLine = styled(LoaderLineMask)`
  animation: ${rotate} 1.2s infinite linear;
`;
const LoaderLine = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
`;
const Text = styled.p`
  font-size: 0.7rem;
  max-width: 120px;
  text-align: center;
  line-height: 1;
  margin: 0;
`;
const move = keyframes`
   0% {
      left: 0;
      opacity: 0;
    }
    35% {
      left: 41%;
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      left: 59%;
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      left: 100%;
      transform: rotate(-180deg);
      opacity: 0;
    }
`;
const Message = styled.div`
  position: absolute;
  width: 600px;
  height: 36px;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  user-select: none;
  cursor: default;
  
  div {
    position: absolute;
    width: 20px;
    height: 36px;
    opacity: 0;
    font-family: Helvetica, Arial, sans-serif;
    animation: ${move} 2s linear infinite;
    transform: rotate(180deg);
    color: rgba(255, 255, 255, 0.4);
    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      animation-delay: 0.8s;
    }

    &:nth-child(6) {
      animation-delay: 1s;
    }

    &:nth-child(7) {
      animation-delay: 1.2s;
    }

    &:nth-child(8) {
      animation-delay: 1.4s;
    }
  }
`;
const Wrap = styled.div``;
