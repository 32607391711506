import React from "react";
import styled from "styled-components";
import { CardHistory } from "../components/cards/history";
import { CardValues } from "../components/cards/values";
import { CustomSwiper, SwiperTouch } from "../components/display/customSwiper";
import { Master } from "../components/layout/master";
import { HistoryHome } from "../components/sections/history";
import { Section } from "../components/sections/section";
import { themes } from "../components/styles/ColorsStyle";
import { MediumText, TextSmall, Wrapper } from "../components/styles/TextStyles";

export function About(props) {
  return (
    <Master {...props}>
      <HistoryHome />
      <Section
        title="Valores"
        // description="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto."
      >
        <CustomSwiper>
          {data_values.map((i, x) => (
            <SwiperTouch key={x}>
              <CardValues {...i} />
            </SwiperTouch>
          ))}
        </CustomSwiper>
      </Section>
      <Wrapped>
        <Medium>
          En TCONTAKTO nos involucramos con el crecimiento de las empresas
          asociadas, ofreciendo mejoras y retroalimentación en los procesos
          operativos. Nuestra oferta comercial es la suma del compromiso de
          nuestros más de 500 colaboradores y las últimas tecnologías
          desarrolladas inhouse con la única finalidad de mejorar la experiencia
          de nuestros socios. Creemos en la comunicación asertiva y constante,
          en TCONTAKTO la comunicación es directa, nuestros gerentes comerciales
          siempre estarán a disposición escuchar, proponer y optimizar nuestros
          procesos y relaciones de negocio:
        </Medium>
        <Text>Beneficios de pertenecer a nuestra gran familia </Text>
        <Text>Planilla desde el día 01 con todos los beneficios de ley</Text>
        <Text>Mejores comisiones del mercado</Text>
        <Text>Beneficios corporativos</Text>
        <Text>Línea de carrera</Text>
        <Text>Excelente clima de trabajo</Text>
      </Wrapped>
      <Section
        title="Comentarios y Recomendaciones"
        // description="¿Por qué somos la mejor empresa de call center?"
      >
        <CustomSwiper
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {data_comments.map((i, x) => (
            <SwiperTouch key={x}>
              <CardHistory {...i}/>
            </SwiperTouch>
          ))}
        </CustomSwiper>
      </Section>
      
    </Master>
  );
}

const Wrapped = styled(Wrapper)`
  background: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
`;
const Medium = styled(MediumText)`
  color: ${themes.light.gray};
`;
const Text = styled(MediumText)`
  position: relative;
  color: ${themes.light.gray};
  padding-left: 32px;
  /* margin: 0; */
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 8px;
    width: 14px;
    height: 6px;
    border-radius: 10px;
    background: ${themes.light.primary};
  }
`;
const data_values = [
  {
    id: 1,
    icon: "io-values-4",
    title: "Innovación",
    description: "Buscamos ser la novedad desde dentro hacia afuera",
    color: { a: "ee3765", b: "f3722e" },
  },
  {
    id: 2,
    icon: "io-values-1",
    title: "Integridad",
    description: "Vivimos como nadie nuestra labor",
    color: { a: "41c0c7", b: "009fc3" },
  },
  {
    id: 3,
    icon: "io-values-5",
    title: "Flexibilidad",
    description: "Cambiamos y nos adaptamos para ser los mejores",
    color: { a: "f57c23", b: "fecc49" },
  },
  {
    id: 4,
    icon: "io-values-2",
    title: "Pasión",
    description: "Damos el máximo por conseguir los objetivos",
    color: { a: "ee3765", b: "d11f1f" },
  },
  {
    id: 5,
    icon: "io-values-3",
    title: "Compromiso",
    description: "Somos transparentes y generamos confianza",
    color: { a: "1b9038", b: "34c759" },
  },
  {
    id: 6,
    icon: "io-values-6",
    title: "Lealtad",
    description:
      "Sentimos la empresa como nuestra y respetamos las reglas que hay en ella",
    color: { a: "5859a9", b: "a56aae" },
  },
  {
    id: 7,
    icon: "io-values-7",
    title: "Dinamismo",
    description:
      "Resolvemos ágilmente los problemas tomando decisiones oportunas para beneficio de todos los involucrados",
    color: { a: "000", b: "8e8e93" },
  },
];

const data_comments = [
  {
    id: 1,
    title: "¿Por qué somos la mejor empresa de call center?",
    description: `“Actualmente estamos
    en continuo crecimiento y expansión, lo que nos convierte en una
    empresa muy atractiva tanto para el personal que desea laborar con
    nosotros como para las empresas. Y lo más importante sobre todo,
    rescatar el valor humano que Tcontakto nos brinda a todos, empezando
    desde los Gerentes hacia todas las áreas, ofrecemos un gran clima
    laboral y una buena comunicación, lo que nos hace tener una identidad
    y cultura propia, motivo por el cual nos hace marcar la diferencia
    frente a la competencia” - Ejecutivo de CallCenter"`,
  },
  {
    id: 2,
    title: "¿Por qué somos la mejor empresa de call center?",
    description: `“TCONTAKTO es la mejor
    empresa porque brinda oportunidad de trabajo a los jóvenes peruanos,
    preocupándose por su capacitación constante a través de plataformas
    interactivas. Existe posibilidad de generar ingresos sin topes
    haciendo que cada colaborador marque su propias metas en cuanto a lo
    que desea ganar, además de crear un ambiente de relación de trabajo
    horizontal donde se valoran todas las opiniones, se esmeran por
    cumplir los reglamentos y políticas acorde a las instituciones
    reglamentarias de trabajo, buscando el bienestar del trabajador” -
    Ejecutivo de CallCenter`,
  },
  {
    id: 3,
    title: "¿Por qué somos la mejor empresa de call center?",
    description: `“Somos la mejor empresa porque aquí encontrarás 2 cosas resaltantes: 
    Formalidad: En Tcontakto la experiencia es distinta, ya que todos nuestros colaboradores ingresan a planilla desde el inicio de sus labores y en el caso de los Ejecutivos, comisionan dentro de planilla, por ende estos ingresos están afectos a sus beneficios sociales.
    Horizontalidad: A diferencia de otras empresas en Tcontakto existe un trato horizontal desde el Ejecutivo de Ventas hasta el Gerente General, siempre respetando con valores que nos representan” - Supervisor de equipos
    `,
  },
]
