import React, {useEffect, useState} from "react";
import { message } from "antd";
import styled from "styled-components";
import { Contact } from "../controllers/contact"
import { Button } from "../buttons/button";
import { MediumText, Wrapper } from "../styles/TextStyles";
import { Checkbox } from "./checkbox";
import { Input } from "./input";

import image from "../../assets/images/form-contact.jpg";
import { media } from "../io";
import { Loader } from "./Loader";

import {useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';


let token = '';
 
 //const captchaRef = React.createRef();

export function Form(props) {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [ruc, setRuc] = useState("");
  const [post, setPost] = useState("");
  const [area, setArea] = useState("");
  //const captchaRef = useRef<ReCAPTCHA>(null);

  const captchaRef = useRef(null);
  
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  const [check, setCheck] = useState(false);


  const [verfiedRecaptcha, setverfiedRecaptcha] = useState(false);

  const onChange = (value) => {
    // console.log("Captcha value antes:", value);
    token = value;
    //console.log("Captcha value despues:", token);
    setverfiedRecaptcha(!verfiedRecaptcha);
  }



  const submitHandler = async () => {
    try {
      if (!/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(email))
        throw {message: "Ingrese un email válido. (example@domain.com)"}
      if (!/^[0-9]{9}$/.test(phone))
        throw {message: "Ingrese un teléfono válido. (números sin espacios de 9 cifras)"}
      if(company === "")
        throw {message: "Ingrese nombre de la empresa en la que trabaja"}
      if (!/^[0-9]{11}$/.test(ruc))
        throw {message: "Ingrese un RUC válido."}
      if (post === "")
        throw {message: "Ingrese el cargo que tiene en la empresa actual."}
      if (area.length == 0)
        throw {message: "Ingrese un mensaje."}
      if (!check)
        throw {message: "Debe aceptar los términos y condiciones."}
      setLoader(true);

      if(token){
        //tokenRecaptcha = token;

        let contact = new Contact()
        let response = await contact.add({name, email, phone, company, ruc, post, area, token })
        if (!response.status)
          throw {message: response.message}

          window.gtag('event', 'conversion', {'send_to': 'AW-473881585/JG3ICNOh--EDEPG3--EB'});


        message.success('Registro exitoso, en breve nos pondremos en contacto.')
        resetForm()

      }else{
        console.log("You must confirm you are not a robot");
      }

      
    } catch (ex) {
      message.error(ex.message)
    } finally {
      setLoader(false);
    }
  }

  function resetForm() {
    setName("")
    setEmail("")
    setPhone("")
    setArea("")
    setCompany("")
    setRuc("")
    setPost("")
    setArea("")
    setCheck(false)
  }

  return (
    <>
      <Wrapped>
        <Card>
          <Cover src={image}/>
          <ContentForm>
            <Title>Contacto para empresas</Title>
            <Input id="form-start" type="text" name="Nombre y Apellido" value={name} onChange={(e) => setName(e.target.value)}/>
            <All>
              <Input type="email" name="Correo" value={email} onChange={(e)=> setEmail(e.target.value)}/>
              <Input type="number" name="Celular" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
              <Input type="text" name="Nombre de la empresa" value={company} onChange={(e)=> setCompany(e.target.value)}/>
              <Input type="number" name="RUC" value={ruc} onChange={(e)=> setRuc(e.target.value)}/>
            </All>
            <Input type="text" name="Cargo que ocupa" value={post} onChange={(e)=> setPost(e.target.value)}/>
            <Input area type="text" name="Mensaje" value={area} onChange={(e)=> setArea(e.target.value)}/>

            <All>
            <ReCAPTCHA 
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
              ref={captchaRef}  />
             
              <Checkbox name="Acepto la Política de Privacidad" value={check} checked={check} onChange={() => setCheck(!check)}/>
              
              <Button title="Enviar"  style={ verfiedRecaptcha ? { } : {pointerEvents: 'none'}} onButton={submitHandler}/>
            </All>
          </ContentForm>
        </Card>
      </Wrapped>
      {loader && <Loader message="Enviando datos"/>}
    </>
  );
}



const Wrapped = styled.div`
  width: 100%;
  margin: 80px auto;
`;



const Card = styled(Wrapper)`
  background: white;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 40px;
  padding: 30px;
  @media (${media.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto;
  }
`;
const Title = styled(MediumText)`
  font-weight: bold;
`;
const Cover = (props) => (
  <WrappedCover>
    <Image {...props} />
  </WrappedCover>
);
const WrappedCover = styled.div`
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ContentForm = styled.div`
  display: grid;
  gap: 20px;
`;
const All = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 0;
  @media (${media.mobileM}) {
    grid-template-columns: 1fr;
  }
`;
