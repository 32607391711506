import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

export function CustomSwiper(props) {
  return (
    <Swiper
      spaceBetween={30}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={
        props.breakpoints || {
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }
      }
      className="io-swiper"
    >
      <div className="swiper-wrapper">{props.children}</div>
    </Swiper>
  );
}

export function SwiperTouch(props) {
  return (
    <SwiperSlide>
      {props.children}
    </SwiperSlide>
  );
}