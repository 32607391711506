import React from "react";
import styled from "styled-components";
import Layout from "../layout";
import { Header } from "./header";

export function Master(props) {
  // const { history } = props;

  function handleCloseAccount() {
    localStorage.removeItem("usr");
    // history.push("/");
  }

  return (
    <Layout>
      
      <Header/>
      <Content>{props.children}</Content>
    </Layout>
  );
}

const Content = styled.div`
  padding-top: 60px;
`;
