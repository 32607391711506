import React from "react";
import styled from "styled-components";
import { CardHistory } from "../cards/history";
import { media } from "../io";
import { themes } from "../styles/ColorsStyle";
import { H2, TextSmall, Wrapper } from "../styles/TextStyles";

export function HistoryHome(props) {
  return (
    <Wrapped id="history-start">
      <Content>
        <Columns>
          <Title>Historia</Title>
          <Description>
            Somos una empresa de servicios de Outsourcing y Contact Center
            especializados en la venta presencial y por teléfono. Nos enfocamos
            en relacionarnos de manera transparente y con calidad en el
            servicio. Creemos fielmente que el trato con nuestros clientes
            finales se basa en la confianza y empatía, siempre brindando
            soluciones para ellos. Por ese motivo constantemente somos
            reconocidos por nuestros clientes del sector financiero y seguros
            como N°1 en ventas.
          </Description>
        </Columns>
        <Columns>
          <CardHistory
            title="Visión"
            description="Ser reconocidos en la Iberoamérica como la mejor empresa BPO y contact center que da a sus resultados que superan sus expectativa en base a procesos óptimos y personas comprometidas e integras."
          />
          <CardHistory
            title="Misión"
            description="En Tcontakto brindamos soluciones de BPO Y Contact center para ayudar a nuestros clientes corporativos a cumplir sus objetivos de manera innovadora, flexible y efectiva; y a nuestros clientes medianos los guiamos con las mejores prácticas del mercado con la finalidad que puedan implementar un servicio de calidad."
          />
        </Columns>
      </Content>
    </Wrapped>
  );
}

const Wrapped = styled.div`
  padding: 100px 0;
  background: white;
`;
const Content = styled(Wrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 40px;
  @media (${media.tablet}) {
    gap: 0;
    grid-template-columns: 1fr;
  }
`;
const Title = styled(H2)`
  color: ${themes.light.gray};
`;
const Description = styled(TextSmall)`
  color: ${themes.light.gray};
`;
const Columns = styled.div``;
