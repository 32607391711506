import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'antd/dist/antd.css';
import "./App.css";
import { Home } from "./screens/home";
import { About } from "./screens/about";
import { Work } from "./screens/work";
import { Blog } from "./screens/blog";
import { Contact } from "./screens/contact";
import { Pagepolicy } from "./screens/pagepolicy";
import { DetailWork } from "./screens/detailWork";
import { DetailBlog } from "./screens/detailBlog";
import { Pageservices } from "./screens/pageservices";
import { Pageclients } from "./screens/pageclients";
import { DataContextProvider } from "./context/DataContext";
import { Contacts } from "./screens/admin/contacts";
import { Applicants } from "./screens/admin/applicants";
import { Servicemidleware } from "./components/controllers/servicemidleware";
import { Blogmidleware } from "./components/controllers/blogmidleware";
import { Pagesgsi } from "./screens/pagesgsi";

function App() {
  const AppInside = (props) => (
    <DataContextProvider>
      <Router>
        <Switch>
          <Route {...props} key={1} exact={true} path="/" component={(props) => <Home {...props} />}/>
          <Route {...props} key={2} exact={true} path="/nosotros" component={(props) => <About {...props} />}/>
          <Route {...props} key={3} exact={true} path="/nuestros-clientes" component={(props) => <Pageclients {...props} />}/>
          <Route {...props} key={4} exact={true} path="/contactanos" component={(props) => <Contact {...props} />}/>
          <Route {...props} key={5} exact={true} path="/politica-privacidad" component={(props) => <Pagepolicy {...props} />}/>         
          <Route {...props} key={6} exact={true} path="/alcance-sgsi" component={(props) => <Pagesgsi {...props} />}/>

          <Route {...props} key={10} exact={true} path="/trabaja-con-nosotros" component={(props) => <Work {...props} />}/>
          <Route {...props} key={11} exact={true} path="/work-info" component={(props) => <DetailWork {...props} />}/>

          <Route {...props} key={20} exact={true} path="/blog" component={(props) => <Blog {...props} />}/>
          <Route {...props} key={21} exact={true} path="/blog-info" component={(props) => <DetailBlog {...props} />}/>
          <Route {...props} key={22} exact={true} path="/blog/:blogroute" component={(props) => <Blogmidleware {...props} />}/>

          <Route {...props} key={23} exact={true} path="/servicios" component={(props) => <Pageservices {...props} />}/>
          <Route {...props} key={24} exact={true} path="/servicios/:serviceroute" component={(props) => <Servicemidleware {...props} /> }/>

          {/*require login*/}
          
          {/*
          <Route {...props} key={100} exact={true} path="/contacts" component={(props) => <Contacts {...props} />}/>
          <Route {...props} key={101} exact={true} path="/applicants" component={(props) => <Applicants {...props} />}/>
          */}

          <Route path="*" component={Home}/>
        </Switch>
      </Router>
    </DataContextProvider>
  );
  return <AppInside />;
}

export default App;
