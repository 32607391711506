import React from "react";
import styled from "styled-components";
import { CardService } from "../components/cards/service";
import { CardRecognitions } from "../components/cards/recognitions";
import { Icon, media } from "../components/io";
import { Master } from "../components/layout/master";
import { Section } from "../components/sections/section";
import WrappedHome from "../components/sections/WrappedHome";
import { Wrapper } from "../components/styles/TextStyles";
import { CardAllied } from "../components/cards/allied";
import { CardValues } from "../components/cards/values";
import { HistoryHome } from "../components/sections/history";
import { CustomSwiper, SwiperTouch } from "../components/display/customSwiper";
import { Services } from "../components/sections/services";
import { Clients } from "../components/sections/clients";



import imageRe1 from "../assets/images/recognitions/t1.png";
import imageRe2 from "../assets/images/recognitions/t7.png";
import imageRe3 from "../assets/images/recognitions/t6.png";
import imageRe4 from "../assets/images/recognitions/t2.png";
import imageRe5 from "../assets/images/recognitions/t3.png";
import imageRe6 from "../assets/images/recognitions/t4.png";
import imageRe7 from "../assets/images/recognitions/t5.png";
import imageRe8 from "../assets/images/recognitions/GPTW_logo.jpg";

export function Home(props) {
  return (
    <Master {...props}>
      <WrappedHome {...props} />
      
      <Services {...props}/>

      <Section
        title="Reconocimientos"
        description="TCONTAKTO ha sido premiada por en diferentes ocasiones por alcanzar los objetivos comerciales de nuestros clientes"
      >
        <CustomSwiper>
          {data_recog.map((i) => (
            <SwiperTouch key={i.id}>
              <CardRecognitions image={i.image} title={i.title} />
            </SwiperTouch>
          ))}
        </CustomSwiper>
      </Section>

      <Clients {...props}/>

    </Master>
  );
}


const GridService = styled(Wrapper)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px;
  @media (${media.tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  @media (${media.movil}) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;


const data_values = [
  { id: 1, icon: "io-values-4", title: "Innovación", description: "Buscamos ser la novedad desde dentro hacia afuera", color: {a: "ee3765", b: "f3722e"}},
  { id: 2, icon: "io-values-1", title: "Integridad", description: "Vivimos como nadie nuestra labor", color: {a: "41c0c7", b: "009fc3"}},
  { id: 3, icon: "io-values-5", title: "Flexibilidad", description: "Cambiamos y nos adaptamos para ser los mejores", color: {a: "f57c23", b: "fecc49"}},
  { id: 4, icon: "io-values-2", title: "Pasión", description: "Damos el máximo por conseguir los objetivos", color: {a: "ee3765", b: "d11f1f"}},
  { id: 5, icon: "io-values-3", title: "Compromiso", description: "Somos transparentes y generamos confianza", color: {a: "1b9038", b: "34c759"}},
  { id: 6, icon: "io-values-6", title: "Lealtad", description: "Sentimos la empresa como nuestra y respetamos las reglas que hay en ella", color: {a: "5859a9", b: "a56aae"}},
  { id: 7, icon: "io-values-7", title: "Dinamismo", description: "Resolvemos ágilmente los problemas tomando decisiones oportunas para beneficio de todos los involucrados", color: {a: "000", b: "8e8e93"}},
];
const data_recog = [

  {
    id: 1,
    image: imageRe1,
    title: "Mejor Fuerza de Ventas Telemarketing 2019",
  },
  { id: 2, image: imageRe2, title: "Mejor Canal de Ventas PLD 2017" },
  {
    id: 8,
    image: imageRe8,
    title: "Reconocimiento como mejor espacio de trabajo GPTW",
  },
  { id: 3, image: imageRe3, title: "Mejor Canal de Ventas TDC 2016" },
  {
    id: 4,
    image: imageRe4,
    title: "Premio a Mejor Canal de Ventas 2016  ",
  },
  { id: 5, image: imageRe5, title: "Mejor FUVEX 2T 2015 " },
  { id: 6, image: imageRe6, title: "Mejor Fuerza de Ventas TDC 2015" },
  {
    id: 7,
    image: imageRe7,
    title: "Mejor Canal Pago de Haberes 4to Trimestre 2015 ",
  }
];
