import React, {useEffect, useState} from "react";
import { Divider, message } from "antd";
import styled from "styled-components";
import { Contact } from "../controllers/contact"
import { Button } from "../buttons/button";
import { themes } from "../styles/ColorsStyle";
import { MediumText, Wrapper } from "../styles/TextStyles";
import { Checkbox } from "./checkbox";
import { Input } from "./input";
import { Select, Tag } from 'antd';

import image from "../../assets/images/form-work.jpg";
import { media } from "../io";
import { Postulant } from "../controllers/postulant";
import { Loader } from "./Loader";

const { Option } = Select;

export function FormWork(props) {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [secondSurname, setSecondSurname] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [document, setDocument] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [district, setDistrict] = useState("");
  const [workingMode, setWorkingMode] = useState("");
  const [experience, setExperience] = useState([]);
  const [typeExperience, setTypeExperience] = useState([]);
  const [workTools, setWorkTools] = useState("");
  const [availability, setAvailability] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [check, setCheck] = useState(false);
    

  const submitHandler = async () => {
    try {
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(name))
        throw {message: "Ingrese un nombre válido. sin carecteres especiales ni acentos"}
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(surname))
        throw {message: "Ingrese un apellido válido. sin carecteres especiales ni acentos"}
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(secondSurname))
        throw {message: "Ingrese segundo apellido válido. sin carecteres especiales ni acentos"}
      if (!/^[0-9]{9}$/.test(phone))
        throw {message: "Ingrese un teléfono válido. (números sin espacios de 9 cifras)"}
      if (!/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(email))
        throw {message: "Ingrese un email válido. (example@domain.com)"}
      if (file === "")
        throw {message: "Adjuntar hoja de vida. (CV)"}
      if (!check)
        throw {message: "Debe aceptar los términos y condiciones."}
      
      setLoader(true);

      let contact = new Postulant();
      let response = await contact.add({
        name,
        surname,
        secondSurname,
        typeDocument,
        document, 
        phone, 
        age,
        email,
        district,
        workingMode,
        experience,
        typeExperience,
        workTools,
        availability,
        jobs,
        file,
        fileName
      })
      if (!response.status)
        throw {message: response.message}
      message.success('Registro exitoso, pronto nos pondremos en contacto.')
      resetForm();
    } catch (ex) {
      message.error(ex.message)
    } finally {
      setLoader(false);
    }
  }

  function resetForm() {
    setName("")
    setSurname("")
    setSecondSurname("")
    setTypeDocument("")
    setDocument("")
    setPhone("")
    setAge("")
    setEmail("")
    setDistrict("")
    setWorkingMode("")
    setExperience([])
    setTypeExperience([])
    setWorkTools("")
    setAvailability([])
    setJobs([])
    setFile("")
    setFileName("")
    setCheck(false)
  }

  const onChangeCV = (e) => {
    setFileName(e.target.files[0].name);
    // convert to base64
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      setFile(e.target.result);
    }
  }

  return (
    <>
      <Wrapped>
        <Card>
          <Cover src={image}/>
          <ContentForm>
            <Title>Trabaja con nosotros</Title>
            <Input id="form-work" type="text" name="Nombres" value={name} onChange={(e) => setName(e.target.value)}/>
            <All>
              <Input type="text" name="Apellido Paterno" value={surname} onChange={(e) => setSurname(e.target.value)}/>
              <Input type="text" name="Apellido Materno" value={secondSurname} onChange={(e) => setSecondSurname(e.target.value)}/>
              <WrappedSelect>
                <Label>Tipo de documento</Label>
                <Select
                showSearch
                placeholder="Tipo de documento"
                optionFilterProp="children"
                value={typeDocument}
                onChange={(value) => setTypeDocument(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="1">DNI</Option>
                <Option value="2">PTP</Option>
                <Option value="3">Carné de extranjería</Option>
              </Select>
              </WrappedSelect>
              <Input type="number" name="Número de documento" value={document} onChange={(e)=> setDocument(e.target.value)}/>
              <Input type="number" name="Número de Celular" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
              {/* Edad */}
              <Input type="email" name="Correo" value={email} onChange={(e)=> setEmail(e.target.value)}/>
              <Input type="text" name="Distrito de residencia" value={district} onChange={(e)=> setDistrict(e.target.value)}/>
              <WrappedSelect>
                <Label>¿Buscas laborar de manera presencial ( Sede Miraflores ) o remoto?</Label>
                <Select
                showSearch
                placeholder="Seleccionar"
                value={workingMode}
                onChange={(value) => setWorkingMode(value)}
              >
                <Option value="1">Presencial</Option>
                <Option value="2">Remoto</Option>
              </Select>
              </WrappedSelect>
              
            </All>
            <Divider/>
            <All>
              <WrappedSelect>
                <Label>¿En qué productos tienes experiencia?</Label>
                <Select
                  mode="multiple"
                  showArrow
                  value={experience}
                  onChange={(value) => setExperience(value)}
                  options={list.products}
                />
              </WrappedSelect>
              <WrappedSelect>
                <Label>¿Qué tipo de experiencia tienes ?¿ Presencial ( en agencias o tiendas) o call center?</Label>
                <Select
                  mode="multiple"
                  showArrow
                  value={typeExperience}
                  onChange={(value) => setTypeExperience(value)}
                  options={list.experience}
                />
              </WrappedSelect>
              <WrappedSelect>
                  <Label>¿Cuentas con Herramientas para trabajar desde casa ( PC / laptop) , internet y headphone ( auriculares con audífonos)?</Label>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    value={workTools}
                    onChange={(value) => setWorkTools(value)}
                  >
                    <Option value="1">SI</Option>
                    <Option value="2">NO</Option>
                  </Select>
                </WrappedSelect>

              <WrappedSelect>
                <Label>¿Disponibilidad de turno?</Label>
                <Select
                  mode="multiple"
                  showArrow
                  options={list.availability}
                  value={availability}
                  onChange={(value) => setAvailability(value)}
                />
              </WrappedSelect>
              <WrappedSelect>
                <Label>Puesto de interés</Label>
                <Select
                  mode="multiple"
                  showArrow
                  options={list.jobs}
                  value={jobs}
                  onChange={(value) => setJobs(value)}
                />
              </WrappedSelect>
              <Input type="file" name="Hoja de vida (CV)" accept="application/pdf" onChange={(e) => onChangeCV(e)}/>
            </All>
            <All>
              <Checkbox name="Acepto la Política de Privacidad" checked={check} value={check} onChange={() => setCheck(!check)}/>
              <Button title="Enviar" onButton={submitHandler}/>
            </All>
          </ContentForm>
        </Card>
      </Wrapped>
      {loader && <Loader message="Enviando datos"/>}
    </>
  );
}

const Wrapped = styled.div`
  width: 100%;
  margin: 80px auto;
`;
const WrappedSelect = styled.div`
  display: grid;
  align-items: center;
  gap: 4px;
`;
const Label = styled(MediumText)`
  color: ${themes.light.gray};
  margin: 0;
  font-size: 16px;
`;
const Card = styled(Wrapper)`
  background: white;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 40px;
  padding: 30px;
  @media (${media.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto;
  }
`;
const Title = styled(MediumText)`
  font-weight: bold;
`;
const Cover = (props) => (
  <WrappedCover>
    <Image {...props} />
  </WrappedCover>
);
const WrappedCover = styled.div`
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
const ContentForm = styled.div`
  display: grid;
  gap: 20px;
`;
const All = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 0;
  @media (${media.mobileM}) {
    grid-template-columns: 1fr;
  }
`;

const list = {
  products: [
    { value: 'Tarjetas de Crédito'},
    { value: 'Préstamos'},
    { value: 'Compra de Deuda'},
    { value: 'Extralínea o Extracash'},
    { value: 'Seguros Oncológicos'},
    { value: 'Portabilidad Perú'},
    { value: 'Portabilidad Público Extranjero'},
  ],
  experience: [
    { value: 'Presencial'},
    { value: 'Call Center'},
  ],
  availability: [
    { value: 'Part Time Turno Mañana'},
    { value: 'Part Time Turno Tarde'},
    { value: 'Sólo Full Time'},
  ],
  jobs: [
    { value: 'EJECUTIVO DE VENTAS - TARJETAS DE CRÉDITO'},
    { value: 'EJECUTIVO DE VENTAS - SEGUROS ONCOLOGICOS'},
    { value: 'SUPERVISOR DE VENTAS - PRODUCTOS FINANCIEROS'},
    { value: 'EJECUTIVO DE VENTAS - PRESTAMOS PERSONALES'},
    { value: 'EJECUTIVO DE VENTAS - PAYRROLL'},
    { value: 'EJECUTIVO DE VENTAS - ENTEL EMPRESAS'},
  ]
}