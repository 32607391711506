export class Contact {
  add = async (obj) => {

    const response = await fetch(
      // "https://services.tcontakto.com/LandingPageTK/wsProd/Contact/sendEmail",
      // "http://localhost:24965/Service1.svc/privacy_policy",
      "https://services.tcontakto.com/LandingPageTK/wsProd/Service1.svc/sendMail",
      {
        method: "POST",
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json;charset=UTF-8",
          // Connection: "keep-alive",
        },
        body: JSON.stringify({
          NAME: obj.name,
          EMAIL: obj.email,
          PHONE: obj.phone,
          COMPANY: obj.company,
          RUC: obj.ruc,
          POST: obj.post,
          TOKEN: obj.token,
          MESSAGE: obj.area
        }),
      }
    );
    let json = await response.json();
    return json;
  };
}
