import React from "react";
import styled from "styled-components";
import { Section } from "../../components/sections/section";
import { CustomSwiper, SwiperTouch } from "../../components/display/customSwiper";
import { CardAllied } from "../../components/cards/allied";

import imageClient1 from "../../assets/images/clients/bbva.png";
import imageClient2 from "../../assets/images/clients/scotiabank.png";
import imageClient3 from "../../assets/images/clients/entel.png";
import imageClient4 from "../../assets/images/clients/pichincha.png";
import imageClient5 from "../../assets/images/clients/rimac.png";
import imageClient6 from "../../assets/images/clients/azteca.png";
import imageClient7 from "../../assets/images/clients/backus.png";
import imageClient8 from "../../assets/images/clients/etna.png";
import imageClient9 from "../../assets/images/clients/dinners.png";
import imageClient10 from "../../assets/images/clients/gnb.png";
import imageClient11 from "../../assets/images/clients/oncosalud.png";
import imageClient12 from "../../assets/images/clients/profuturo.png";
import imageClient13 from "../../assets/images/clients/santander.png";
import imageClient14 from "../../assets/images/clients/usil.png";
import imageClient15 from "../../assets/images/clients/xerox.png";

export function Clients(props) {
    return (
  
        <Section
        id="clients-start"
        title="Clientes"
        description="A lo largo de nuestra trayectoria hemos construído una gran familia."
      >
        <CustomSwiper
          breakpoints={{
            "@0.00": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 6,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 8,
              spaceBetween: 50,
            },
          }}
        >
          {data_allied.map((i) => (
            <SwiperTouch key={i.id}>
              <CardAllied title={i.name} image={i.logo} title={i.name} />
            </SwiperTouch>
          ))}
        </CustomSwiper>
      </Section>
  
    );
  }

  const data_allied = [
    { id: 1, logo: imageClient1, name: "BBVA" },
    { id: 2, logo: imageClient2, name: "Scotiabank" },
    { id: 3, logo: imageClient3, name: "Entel" },
    { id: 4, logo: imageClient4, name: "Pichincha" },
    { id: 5, logo: imageClient5, name: "Rimac" },
    { id: 6, logo: imageClient6, name: "Azteca" },
    { id: 7, logo: imageClient7, name: "Backus" },
    { id: 8, logo: imageClient8, name: "ETNA" },
    { id: 9, logo: imageClient9, name: "Dinners" },
    { id: 10, logo: imageClient10, name: "GNB" },
    { id: 11, logo: imageClient11, name: "Oncosalud" },
    { id: 12, logo: imageClient12, name: "Profuturo" },
    { id: 13, logo: imageClient13, name: "Santander" },
    { id: 14, logo: imageClient14, name: "USIL" },
    { id: 15, logo: imageClient15, name: "Xerox" },
  ];
  