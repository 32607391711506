import React from "react";
import styled from "styled-components";
import { Button } from "../buttons/button";
import { Icon, media } from "../io";
import { themes } from "../styles/ColorsStyle";
import { H2, MediumText, TextSmall, Wrapper } from "../styles/TextStyles";

import image from "../../assets/images/home.jpg";

export default function WrappedHome(props) {
  function toContact() {
    props.history.push("/contactanos");
  }

  return (
    <Container>
      <Column>
        <Title>TCONTAKTO</Title>
        <Description>
          Somos una empresa peruana que brinda servicios de Contact Center,
          campañas de telemarketing, atención al cliente, toma de pedidos y
          cobranzas.
        </Description>
        <Row>
          <Button title="Contáctanos" onButton={toContact} />
          {/* <Button title="Leer más" ghost /> */}
        </Row>
      </Column>
      <CoverImage>
        <Image src={image} />
      </CoverImage>
    </Container>
  );
}
const Container = styled(Wrapper)`
  padding: 0;
  min-height: 400px;
  display: grid;
  grid-template-columns: 440px 1fr;
  overflow: hidden;
  ${themes.light.card};
  @media (${media.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }
`;
const Title = styled(H2)`
  z-index: 2;
  color: ${themes.light.gray};
`;
const Description = styled(MediumText)`
  z-index: 2;
  color: ${themes.light.gray};
`;
const Column = styled.div`
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  backdrop-filter: blur(40px);
  ${themes.light.card}
  background: rgba(255, 255, 255, .75);
  @media (${media.tablet}) {
    padding: 30px;
  }
`;
const Row = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: flex-end;
`;
const CoverImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (${media.tablet}) {
    height: 260px;
  }
`;
const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
