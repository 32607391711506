import React from "react";
import { Services } from "../components/sections/services";
import { Master } from "../components/layout/master";


export function Pageservices(props) {
  return (
    <Master {...props}>
        <Services {...props}/>
    </Master>
  );
}