import React, { useContext, useEffect } from "react";

import { Home } from "../../screens/home";
import { DetailWork } from "../../screens/detailWork";
import { DataContext } from "../../context/DataContext";

import imageService1 from "../../assets/images/services/service-1.jpg";
import imageService2 from "../../assets/images/services/service-2.jpg";
import imageService3 from "../../assets/images/services/service-3.jpg";
import imageService4 from "../../assets/images/services/service-4.jpg";
import imageService5 from "../../assets/images/services/service-5.jpg";
import imageService6 from "../../assets/images/services/service-6.jpg";


export function Servicemidleware(props) {
  const { history } = props;
  const { setData } = useContext(DataContext);

  let url_service = props.match.params.serviceroute;
  let current_data = data_services.find(x => x.url === url_service.toLowerCase());
  
    if(current_data != undefined){
      setData(current_data);
      return(
        <DetailWork {...props} />
      );
    }else{
      return(
        <Home {...props} />
      );
    }
}

const data_services = [
  {
    id: 1,
    url:"atencion-cliente",
    image: imageService1,
    title: "Atención al Cliente",
    detail: "En TCONTAKTO tenemos personal calificado en los más altos estándares de atención. Nuestro servicio de atención al cliente consiste en la atención personalizada de llamadas e interacciones entrantes, el mismo que está orientado a aquellas empresas que deseen ofrecer una atención integral y a la medida para sus usuarios finales. Cuidamos a tu cliente y garantizamos la mejor atención a fin de mantenerlos fidelizados con tu marca.",
  },
  {
    id: 2,
    url:"televentas-marketing",
    image: imageService2,
    title: "Televentas y Marketing",
    detail: 'Entendemos que los procesos de ventas son vitales para su negocio y que estos requieren un método de trabajo, habilidades y destrezas especializadas para hacer efectivo cada contacto con sus clientes.\nEs por esto que ponemos a su disposición nuestra experiencia, conocimiento, tecnología y talento humano para ayudarlo a dinamizar sus relaciones comerciales.',
  },
  {
    id: 3,
    url:"toma-pedidos",
    image: imageService3,
    title: "Toma de Pedidos",
    detail: "Si bien cada día son más las personas que acceden y realizan pedidos por internet, no se debe descuidar a aquellos clientes que optan por un canal de pedidos más tradicional. Por ello, gracias a nuestro sistema de gestión y personal capacitado, podemos atender de forma oportuna y completa a quienes decidan realizar su pedido por vía telefónica. Manejamos de forma eficiente los tiempos de atención e ingreso de pedido en vivo para maximizar los niveles de satisfacción de cliente, incrementar ventas y reducir cola de espera en horas pico. Contamos con herramientas de gestión dinámica, central IVR y monitoreo constante de calidad para brindarle una solución a medida en pos de la mejor experiencia del cliente."
  },
  {
    id: 4,
    url:"cobranzas-telefonicas",
    image: imageService4,
    title: "Cobranzas Telefónicas",
    detail: "Nuestra experiencia en negociación efectiva, así como la disponibilidad de múltiples canales y desarrollos tecnológicos específicos para la recuperación de la deuda, constituyen nuestras mejores herramientas para garantizar una gestión eficiente, con altos estándares de calidad, que supere las expectativas de nuestros clientes. Ofrecemos un servicio ligado a una metodología específica y única, que nos hace ser exclusivos en el mercado, con un proceso integral de gestión para la reclamación de impagados. Nuestras estrategias están dirigidas a alcanzar los objetivos de recuperación establecidos para cada cartera, preservando al mismo tiempo la confianza del cliente final y la imagen de la marca."
  },
  {
    id: 5,
    url:"help-desk",
    image: imageService5,
    title: "Help Desk",
    detail: "Es importante mantener un canal de atención para tus clientes – sean externos o internos. Nuestro servicio de mesa de ayuda puede proporcionar respuestas y soluciones a usuarios finales, clientes o beneficiarios, además de brindar asesoramiento en relación a la organización o institución, productos y servicios. Nuestra prioridad es dejar a tus usuarios satisfechos con las gestiones de nuestros analistas de mesa de ayuda, basados en un conocimiento profundo del knowhow de tus procesos o servicios."
  },
  {
    id: 6,
    url:"servicios-bpo",
    image: imageService6,
    title: "Servicios BPO",
    detail: "Cobranzas personalizadas, atención remota para dispositivos electrónicos o productos, soporte de posventa, agendamiento proactivo y predictivo de citas o lo que requieras. Si tú necesitas una solución a medida, nosotros lo diseñamos, revisamos los procesos, capacitamos a nuestros agentes y analistas y desplegamos nuestra solución integral velando por transmitir los valores de tu empresa y cuidando a tus clientes como nuestros: nuestra prioridad es la excelencia."
  },
];
