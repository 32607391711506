import React, {createContext, useState} from 'react';

export const DataContext = createContext();

const dataFix = { hola: "123 ajhksd" }

export const DataContextProvider = ({children}) => {
  const [data, setData] = useState(dataFix);
  return (
    <DataContext.Provider value={{data, setData}}>
      {children}
    </DataContext.Provider>
  );
}